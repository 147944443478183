import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../components/layouts/courseLandingLayout"
import Button from "../components/buttons/linkButton"
import { Container, Col, Row } from "react-bootstrap"
import styled from "styled-components"
import {
  TwoToneBackground,
  HeroContainer,
  ProblemSection,
  StatsSection,
  CompareSection,
  CurriculumHero,
  TestimonySection,
  GradRow,
} from "../components/styles/HomePageStyles"

import Navbar from "../components/navbars/courseLandingNavbar"
import ReactGA from "react-ga"

import Zulmy from "../assets/images/zulmy.jpg"
import Pierre from "../assets/images/pierre.jpg"
import IBM from "../assets/images/ibm-2.png"
import CreditSuisse from "../assets/images/creditsuisse.png"
import Chart from "../components/comparison"
import curriculum from "../assets/images/curriculum.png"
import BestBootcamp from "../assets/icons/best_coding_bootcamp.png"
import ApplyBox from "../components/applyBox"
import FocusLeadGen from "../components/focusCourseExitIntent"
import SearchEngine from "../components/SearchEngine"
import SyllabusForm from "../components/forms/syllabusForm"
import PSModal from "../components/modal"
import Mike from "../assets/images/mike.jpg"
import Relias from "../assets/images/relias.png"
import Lauren from "../assets/images/lauren.jpg"
import Josh from "../assets/images/josh.jpg"
import Feedstation from "../assets/images/feedstation.png"
import Jeremy from "../assets/images/jeremy.jpg"
import ICF from "../assets/images/icf.png"
import Connor from "../assets/images/connor.jpg"
import AIMSCO from "../assets/images/aimsco.png"
import Stef from "../assets/images/stef.jpg"
import Openasset from "../assets/images/openasset.png"
import NewsLetterForm from "../components/forms/newsletterForm"
import Matt from "../assets/images/matt.jpg"
import { RiArrowRightSLine } from "react-icons/ri"
import { RiArrowLeftSLine } from "react-icons/ri"

import fidelity from "../assets/images/fidelity.png"

import useExitIntent from "../utils/exit-intent-hook"
import ExitIntentModal from "../components/exit-modal"
import Faq from "../components/faq"

const VideoContainer = styled(Container)`
  padding-top: 80px;
  padding-bottom: 60px;
  max-width: 800px;
  h3 {
    text-align: center;
  }
  .cta-container {
    text-align: center;
  }
  img {
    border: 4px solid var(--black);
    box-shadow: -15px 15px var(--black);
  }
`

const Index = ({ location }) => {
  const isBrowser = typeof window !== "undefined"
  const isMobile = isBrowser ? window.innerWidth < 767 : false
  const [show, setShow] = useState(false)
  const [row1, setRow] = useState(true)

  const handleClose = () => setShow(false)
  const handleShow = e => setShow(true)

  const fireGTMEvent = () => {
    ReactGA.event({
      category: "CTA - Button",
      action: "Click",
      label: "waitlist",
    })
  }

  const { showPopup, setShowPopup } = useExitIntent()

  const renderArrows = () => {
    if (row1) {
      return (
        <div className="right-arrow-container" onClick={() => setRow(false)}>
          <RiArrowRightSLine />
        </div>
      )
    } else {
      return (
        <div className="left-arrow-container" onClick={() => setRow(true)}>
          <RiArrowLeftSLine />
        </div>
      )
    }
  }

  const renderGrads = () => {
    if (row1) {
      return (
        <>
          <Col md={4} className="grads-1">
            <div className="test-container">
              <div className="test-img-box">
                <img alt="" src={Mike}></img>
              </div>
              <div className="test-info-container">
                <Row>
                  <Col lg={7}>
                    <p className="test-name">Mike F.</p>
                  </Col>
                  <Col lg={5}>
                    <p className="test-company">
                      <img alt="" src={fidelity}></img>
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="test-container">
              <div className="test-img-box">
                <img alt="" src={Lauren}></img>
              </div>
              <div className="test-info-container">
                <Row>
                  <Col lg={7}>
                    <p className="test-name">Lauren N.</p>
                  </Col>
                  <Col lg={5}>
                    <p className="test-company">
                      <img alt="" src={Relias}></img>
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="test-container">
              <div className="test-img-box">
                <img alt="" src={Josh}></img>
              </div>
              <div className="test-info-container">
                <Row>
                  <Col lg={7}>
                    <p className="test-name">Josh K.</p>
                  </Col>
                  <Col lg={5}>
                    <p className="test-company">
                      <img alt="" src={Feedstation}></img>
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </>
      )
    } else {
      return (
        <>
          <Col md={4} className="grads-2">
            <div className="test-container">
              <div className="test-img-box">
                <img alt="" src={Jeremy}></img>
              </div>
              <div className="test-info-container">
                <Row>
                  <Col lg={7}>
                    <p className="test-name">Jeremy W.</p>
                  </Col>
                  <Col lg={5}>
                    <p className="test-company">
                      <img alt="" src={ICF}></img>
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="test-container">
              <div className="test-img-box">
                <img alt="" src={Connor}></img>
              </div>
              <div className="test-info-container">
                <Row>
                  <Col lg={7}>
                    <p className="test-name">Connor D.</p>
                  </Col>
                  <Col lg={5}>
                    <p className="test-company">
                      <img alt="" src={AIMSCO}></img>
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="test-container">
              <div className="test-img-box">
                <img alt="" src={Stef}></img>
              </div>
              <div className="test-info-container">
                <Row>
                  <Col lg={7}>
                    <p className="test-name">Stef S.</p>
                  </Col>
                  <Col lg={5}>
                    <p className="test-company">
                      <img alt="" src={Openasset}></img>
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </>
      )
    }
  }

  return (
    <Layout>
      <SearchEngine title="Online Coding Bootcamp" />
      <TwoToneBackground>
        <Navbar location={location} />
        <PSModal handleClose={handleClose} handleShow={handleShow} show={show}>
          <SyllabusForm close={handleClose} />
        </PSModal>
        <ExitIntentModal show={showPopup} setShowPopup={setShowPopup}>
          <FocusLeadGen />
        </ExitIntentModal>
        <HeroContainer>
          <Row>
            <Col className="col-hero-image" md={5}>
              <div>
                <img
                  style={{ maxWidth: "100%" }}
                  src={Matt}
                  alt="Interactive learning environment"
                ></img>
              </div>
            </Col>
            <Col className="col-hero-text" md={7}>
              <p className="pretext">
                An Online Code School that Teaches You to
              </p>
              <h1>Start Building Software</h1>
              <p className="subtext">
                Exclusive, mentor-based cohorts limited to 10 students. Get a
                job in tech,{" "}
                <a
                  rel="noopener"
                  target="_blank"
                  href="https://parsity.notion.site/Parsity-Job-Guarantee-0db06ad8845e48d7921e7a087d8d1b89"
                >
                  guaranteed.
                </a>
              </p>

              <Link to="/apply">
                <Button
                  link={true}
                  fireGTMEvent={fireGTMEvent}
                  text={"Apply Now"}
                />
              </Link>
              <Button
                classToAdd="cta-syllabus"
                handleCTAClick={() => setShow(true)}
                text={"Download Our Syllabus"}
              />
            </Col>
          </Row>
        </HeroContainer>
      </TwoToneBackground>
      <Container>
        <Row style={{ margin: "1em" }}>
          <a
            style={{ margin: "auto" }}
            href="https://www.collegeconsensus.com/bootcamps/rankings/best/#parsity"
          >
            <img
              style={{ margin: "auto" }}
              width={150}
              alt="Award: Best Bootcamp 2024"
              src={BestBootcamp}
            ></img>
          </a>
        </Row>
      </Container>

      <StatsSection>
        <Container>
          <h2>Driven by Outcomes</h2>
          <Row>
            <Col md={4}>
              <h3>92% Job Placement</h3>
              <p>
                Most Graduates land software engineering roles within 3 months.
              </p>
            </Col>
            <Col md={4}>
              <h3>Personalized Mentorship</h3>
              <p>
                Direct guidance from professionals to navigate your career
                change.
              </p>
            </Col>
            <Col md={4}>
              <h3>Real-World Ready</h3>
              <p>
                Our curriculum is designed to make you prepared to build complex
                software.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p className="stats-explanation">
                Note: Stats can be tricky. See how we came up with these{" "}
                <a href="https://parsity.notion.site/Parsity-Annual-Report-and-Students-Outcomes-6e8513301352431daebc7465c1e02c8e">
                  stats here
                </a>
                . We are currently working on our 2023 report and will be
                released ASAP.
              </p>
            </Col>
          </Row>
        </Container>
      </StatsSection>
      <ProblemSection>
        <Container>
          <Row>
            <Col md={12}>
              <h2>
                To Land A Software Engineering Job, You Have to Be Able to Build
                Software
              </h2>
              <p className="problem-subtitle">But There's a Problem...</p>
              <div className="problems">
                <div className="problem">
                  <p>Learning it on your own is nearly impossible</p>
                </div>
                <div className="problem">
                  <p>What you’ve tried so far isn’t working</p>
                </div>
                <div className="problem">
                  <p>If you're not already building, you're doing it wrong</p>
                </div>
                <div className="problem">
                  <p>Nobody has given you a plan</p>
                </div>
                <div className="problem">
                  <p>The path to “job ready” isn’t clear</p>
                </div>
                <div className="problem">
                  <p>You don't have anyone to go to with your questions</p>
                </div>
              </div>
              <p className="problem-subtitle">
                Parsity has everything you need to become a Software Engineer,
                but it's for those that want to build. Apply to find out if
                you're a good a fit.
              </p>
            </Col>
          </Row>
        </Container>
      </ProblemSection>
      <TestimonySection>
        <Container>
          <Row>
            <Col md={12}>
              <h2>Parsity Graduates</h2>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="test-container">
                <div className="test-img-box">
                  <img alt="" src={Pierre}></img>
                </div>
                <p className="test-text">
                  It goes without saying (but I'm going to say it) - I couldn't
                  have gotten to this point without everyone from Parsity.
                  Choosing Parsity really was the best investement I've ever
                  made in myself/my career.
                </p>
                <hr />
                <div className="test-info-container">
                  <Row>
                    <Col md={7}>
                      <p className="test-name">
                        Pierre Lourens, Software Engineer at IBM
                      </p>
                    </Col>
                    <Col md={5}>
                      <p className="test-company">
                        <img alt="" src={IBM}></img>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="test-container test-padding-top">
                <div className="test-img-box">
                  <img alt="" src={Zulmy}></img>
                </div>
                <p className="test-text">
                  3 weeks after Parsity I accepted a job as a Junior Java
                  Developer. I'm so grateful for the curriculum at Parsity , the
                  people I met at Parsity, and for this risk that I took on that
                  ultimatley changed my life and now I can say I am doing what I
                  love.
                </p>
                <hr />
                <div className="test-info-container">
                  <Row>
                    <Col md={7}>
                      <p className="test-name">
                        Zulmy Cortez, Software Engineer at Credit Suisse
                      </p>
                    </Col>
                    <Col md={5}>
                      <p className="test-company">
                        <img alt="" src={CreditSuisse}></img>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <GradRow>
            {renderGrads()}
            {!isMobile && renderArrows()}
          </GradRow>
        </Container>
      </TestimonySection>
      <CompareSection>
        <Container>
          <Row className="compare-row">
            <CurriculumHero fluid>
              <Row>
                <Col className="curriculum-left" md={7}>
                  <h3>A Curriculum That Prepares You for the Job</h3>
                  <p className="compare-text">
                    The life you want includes meaningful work - our curriculum
                    paired with your passion will get you there. Parsity grads
                    can do what CS grads can't and know what Bootcamps grads
                    don't.
                    <br></br>
                    <br></br>
                    <a href="/course#curriculum">
                      <Button
                        href="/course#curriculum"
                        text={"See Curriculum"}
                      />
                    </a>
                  </p>
                </Col>
                <Col className="curriculum-right" md={5}>
                  <div className="cover">
                    <img src={curriculum} alt=""></img>
                  </div>
                </Col>
              </Row>
            </CurriculumHero>
          </Row>
          <Row className="comparison-chart">
            <Chart />
          </Row>
        </Container>
      </CompareSection>

      <VideoContainer>
        <iframe
          width="100%"
          height="230"
          src={"https://www.youtube.com/embed/" + "HU4lyGthLGE"}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </VideoContainer>

      <div style={{ paddingBottom: "80px" }}>
        <Container>
          <Row>
            <Col md={12}>
              <div className="text-center btn-apply-problem">
                <p className="problem-subtitle">
                  Sometimes you just need to talk to someone. Schedule a chat to
                  get clearer on your path towards software engineering. At
                  worst, you'll walk away with a clear plan of action. We've
                  done over 500 of these chats. I promise it'll be worth your
                  time.
                </p>
                <Link to="https://calendly.com/brianjenney83/15min">
                  <Button
                    href="https://calendly.com/brianjenney83/15min"
                    fireGTMEvent={fireGTMEvent}
                    text={"Chat with the Founder"}
                  />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row
          style={{
            marginTop: "20px",
            padding: "3em",
            margin: "auto",
            display: "block",
          }}
        >
          <h2 style={{ textAlign: "center" }}>FAQ</h2>
          <Faq
            data={[
              {
                question: "How does the program work?",
                answer:
                  "Students work in cohorts with guidance from their own personal mentor who is a professional software developer (not a student). There are evals each student completes that will include feedback from a senior engineer just like you would receive in a professional work environment. After gradutating by completing a fullstack capstone project, you are paired with a career coach to begin your job search. Throughout the program we have speakers from the industry to give you a taste of what it's like to work in the field and make connections.",
              },
              {
                question: "Will I be able to get a job after the program?",
                answer: `Yes. Between our curriculum, individual support and dedicated career mentorship program, we are confident you will find a career in tech. We have very high employment rates to back this up. You can read more about our outcomes <a href="https://parsity.notion.site/Parsity-Annual-Report-and-Students-Outcomes-6e8513301352431daebc7465c1e02c8e.">here</a> If you don't get hired after 6 months from graduating, we will refund your tuition. There are some conditions to this guarantee, but we are confident in our program and the support we provide. You can read about the get-a-job-or-get-your-money-back guarantee <a href="https://parsity.notion.site/Parsity-Job-Guarantee-0db06ad8845e48d7921e7a087d8d1b89">here</a>`,
              },
              {
                question: "Is financial aid available?",
                answer: `About half of our students use
              <a href="https://partner.ascentfunding.com/parsity/">
                Ascent
              </a>
              or <a href="https://coach.lending.online/parsity">Coach Financing</a> for financing. We offer multiple discounts for early enrollment as well and our tuition is about half of what you would pay at a traditional bootcamp.`,
              },
              {
                question: "What is the time commitment?",
                answer:
                  "The full time program is intensive and will require that you have up to 40 hours a week for studying and completing projects. The part time program is less intensive and will require about 20 hours a week. We have many students with full time jobs and families that complete the program.",
              },
              {
                question: "How do you keep tuition affordable?",
                answer:
                  "Short answer: we are privately owned. We don't have investors to pay back. We are able to keep our tuition low because we are a small team and we don't have a lot of overhead. The curriculm is more than what you will learn at more expensive programs. Our curriculm is yours for life. We are able to keep up with industry trends while the big players can not. We are a small program made up of full time software engineers and we plan to keep it that way.",
              },
            ]}
          />
        </Row>
      </Container>
      <ApplyBox text="Start Building" />
      <Row style={{ marginTop: "20px", padding: "1em", margin: "auto" }}>
        <Col md={{ span: 6, offset: 3 }}>
          <h2>Launch and grow your coding career, faster.</h2>
          <br />
          <p>
            <b>Grab your Software Developer Roadmap</b>. A clear path on what to
            learn, a short e-book on JavaScript, a side project cheat sheet and
            challenges to test your skills.
          </p>
          <p>
            Every once in a while I will write to you. I don't spam. Unsubscribe
            when it's no longer useful.
          </p>
          <NewsLetterForm />
        </Col>
      </Row>
    </Layout>
  )
}

export default Index
