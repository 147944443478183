import styled from "styled-components"
import { Container, Row } from "react-bootstrap"
import Bullet from "../../assets/icons/bullet.png"

export const TwoToneBackground = styled.div`
  //background: linear-gradient(to left, #ffffff 70%, #ed5942 30%);
  padding-bottom: 80px;

  @media screen and (max-width: 767px) {
    background: none;
    background-size: cover;
    background-position: center center;
  }

  .cta-learn-more {
    margin-left: 20px;
  }
`

export const HeroContainer = styled(Container)`
  max-width: 1000px;
  padding-top: 140px;
  font-family: sans-serif;

  .pretext {
    padding-top: 40px;
    font-weight: bold;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .subtext {
    font-style: italic;
    font-size: 20px;
  }

  .cta-syllabus {
    margin-left: 20px;
    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
  }

  .col-hero-image {
    img {
      box-shadow: -20px 20px var(--black);
      border: 5px solid var(--black);
    }
    position: static;
  }

  .col-hero-text {
    padding-left: 40px;
    h1 {
      padding-top: 5px;
      padding-bottom: 15px;
    }
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 24px;
    }
    img {
      max-width: 400px;
      display: block;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  }
`

export const ProblemSection = styled.section`
  background-color: var(--darkGrey);

  .capacity-light-text {
    p {
      color: #fff;
    }
  }

  h2 {
    margin-top: 80px;
    text-align: center;
    color: var(--white);
  }
  .problem-subtitle {
    text-align: center;
    max-width: 580px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 40px;
    color: var(--white);
  }
  .problems {
    color: var(--white);
    background-color: #fff;
    border-color: #e8ebed;
    border: 1px solid #bbbecc;
    border-bottom: 0;
    box-shadow: -10px 10px var(--black);
    padding-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: left;
    position: relative;
    max-width: 870px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .problem {
    border-color: #e8ebed;
    width: 50%;
    border-bottom: 1px solid #bbbecc;
    padding: 20px;
    position: relative;
    padding-left: 20px;

    p {
      padding-bottom: 0;
      margin: 0;
      font-size: 1em;
      background-position: 0 0;
      background-size: 15px 15px;
      line-height: 15px;
      min-height: 15px;
      text-align: left;
      background-image: url(${Bullet});
      background-repeat: no-repeat;
      padding: 0 0 0 40px;
    }

    &:nth-child(2n) {
      border-left: 1px solid #bbbecc;
    }

    @media screen and (max-width: 767px) {
      width: 100%;

      &:nth-child(2n) {
        border-left: none;
      }
    }
  }

  .btn-apply-problem {
    margin-bottom: 40px;
  }
`
export const StatsSection = styled.section`
  padding-top: 80px;
  padding-bottom: 20px;
  background-color: #ecf1f4;

  h2 {
    padding-bottom: 40px;
  }

  h2,
  h3,
  p {
    text-align: center;
  }

  p {
    padding-top: 15px;
  }

  .stats-explanation {
    text-align: center;
    font-size: 14px;
  }
`

export const CompareSection = styled.section`
  background-color: #ecf1f4;
  padding-top: 80px;

  h2 {
    margin-top: 80px;
    text-align: center;
  }

  .compare-title {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .compare-text {
    padding-top: 20px;
    padding-right: 40px;
  }

  .comparison-chart {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  .comp-logo-img {
    max-width: 130px;
  }

  .btn-curriculum {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
  }
`

export const CurriculumHero = styled(Container)`
  .curriculum-left {
    p {
      font-size: 20px;
      font-weight: 400;
      padding-bottom: 0px;
    }

    .subtext-launch {
      font-size: 18px;
      font-style: italic;
    }
  }

  .curriculum-right {
    padding: 0;
    max-width: 1000px;

    img {
      box-shadow: -15px 15px var(--black);
      border: 5px solid var(--black);
    }
  }
`

export const TestimonySection = styled.section`
  h2 {
    margin-top: 80px;
    text-align: center;
  }

  padding-bottom: 50px;

  .hired-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 40px;
  }

  .test-container {
    border: 5px solid var(--black);
    margin: 20px;
    background-color: #fff;
    box-shadow: -15px 15px var(--black);
    position: relative;
    margin-top: 150px;
  }

  .test-text {
    padding: 40px 20px 20px 20px;
    margin: 0;
    font-size: 0.875em;
  }

  .test-img-box {
    border: 5px solid var(--black);
    max-width: 150px;
    box-shadow: -10px 10px var(--black);
    position: absolute;
    top: -90px;
    left: 30px;

    img {
      width: 100%;
      margin: 0;
    }
  }

  .test-info-container {
    padding: 5px 20px 5px 20px;
  }

  .test-name {
    font-size: 0.875em;
  }

  .test-company {
    img {
      width: 100%;
      max-width: 150px;
      display: block;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 767px) {
    .test-padding-top {
      margin-top: 100px;
    }
  }
`

export const GradRow = styled(Row)`
  position: relative;

  .test-info-container {
    padding: 25px 20px 5px 20px;
  }

  .test-name {
    text-align: center;
    position: relative;
    top: 60%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
  }

  .test-img-box {
    border: 5px solid var(--black);
    max-width: 100px;
    box-shadow: -10px 10px var(--black);
    position: absolute;
    top: -75px;
    left: 30px;

    @media screen and (max-width: 1200px) {
      top: -85px;
    }

    img {
      width: 100%;
      margin: 0;
    }
  }

  .test-company {
    margin-bottom: 5px;
  }

  .right-arrow-container {
    font-size: 70px;
    position: absolute;
    right: -40px;
    top: 50%;

    cursor: pointer;
  }

  .left-arrow-container {
    font-size: 70px;
    position: absolute;
    left: -40px;
    top: 50%;

    cursor: pointer;
  }
`
